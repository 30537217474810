<template>
  <div>
    <div class="welcome-container row">
      <div class="col-12 title">بيانات مدير الجمعية:</div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="information-container mt-2 mb-2">
          <img
            style="margin-right: 1rem"
            :src="`${staticUrl}/static/images/info.png`"
            alt="info"
            height="20px"
            width="20px"
          />
          <div style="margin-right: 0.5rem">
            يلزم أن يكون المسجل مدير الجمعية.
          </div>
        </div>
      </div>
    </div>

    <form class="form-step1 w-100">
      <div class="form-label-group mb-3">
        <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="national_id"
              type="number"
              v-model="payload.national_id"
              :class="[
                'w-100',
                {
                  'p-invalid':
                    submitted && (!payload.national_id || !verified_national_id)
                      ? true
                      : false,
                },
              ]"
              :disabled="loading || $parent.verified_info.first_name"
            />
            <label for="national_id">رقم الهوية</label>
          </span>
        </div>
      </div>

      <div class="form-label-group mb-3">
        <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="email"
              type="email"
              v-model="payload.email"
              :class="[
                'w-100',
                {
                  'p-invalid':
                    (submitted && !payload.email) ||
                    (payload.email && !verified_email)
                      ? true
                      : false,
                },
              ]"
              :disabled="
                loading ||
                payload.is_email_verified ||
                $parent.verified_info.first_name
              "
            />
            <label for="email">البريد الإلكتروني</label>
          </span>
          <div
            v-if="payload.email && !verified_email"
            class="invalid-email-label"
            style="color: #e24c4c"
          >
            الرجاء إدخال عنوان بريد إلكتروني صالح
          </div>
        </div>
      </div>

      <div class="form-label-group">
        <div class="mt-4">
          <span class="p-float-label">
            <BaseInputNumber
              :label="'رقم الجوال'"
              v-model="payload.mobile_number" 
              :disabled="loading ||
                payload.is_mobile_verified ||
                $parent.verified_info.first_name"
              :class="{'p-invalid': !!((submitted && !payload.mobile_number) ||
                    (payload.mobile_number && !verify_mobile(payload.mobile_number)))}"
              id="mobile_number"
              :maxLength="10"
              :phoneNumber="true"
            />
          </span>
          <div
            v-if="payload.mobile_number && !verify_mobile(payload.mobile_number)"
            class="invalid-email-label"
            style="color: #e24c4c"
          >
            الرجاء إدخال رقم جوال صحيح
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="form-label-group col-lg-6">
          <div class="mt-4">
            <span class="p-float-label">
              <BaseCalendar
                calendar-id="gregorian-birthdate"
                type="gregorian"
                placeholder="تاريخ الميلاد (ميلادي)"
                v-model="payload.gregorian_birthdate"
                :class="[
                  'w-100',
                  {
                    'p-invalid':
                      submitted && !payload.gregorian_birthdate ? true : false,
                  },
                ]"
                :disabled="loading || $parent.verified_info.first_name"
                :maxDate="currentdDate"
                @dateChange="payload.hijri_birthdate = $event"
              />
            </span>
          </div>
        </div>

        <div class="form-label-group col-lg-6">
          <div class="mt-4">
            <span class="p-float-label">
              <BaseCalendar
                calendar-id="hijri-birthdate"
                type="hijri"
                placeholder="تاريخ الميلاد (هجري)"
                v-model="payload.hijri_birthdate"
                :class="[
                  'w-100',
                  {
                    'p-invalid':
                      submitted && !payload.hijri_birthdate ? true : false,
                  },
                ]"
                :disabled="loading || $parent.verified_info.first_name"
                :maxDate="currentdDate"
                @dateChange="payload.gregorian_birthdate = $event"
              />
            </span>
          </div>
        </div>
      </div>

      <div class="accept-checkbox mt-3">
        <Checkbox
          v-model="payload.the_charity_manager_is_the_chairman"
          :binary="true"
          inputId="chkbox1"
          :disabled="loading"
        />
        <label for="chkbox1"> مدير الجمعية هو رئيس مجلس إدارتها؟ </label>
      </div>
      <div v-if="!payload.the_charity_manager_is_the_chairman">
        <div class="form-label-group mb-3">
          <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="chairman_name"
              type="text"
              v-model="payload.chairman_name"
              :class="[
                'w-100',
                {
                  'p-invalid':
                    submitted && !payload.chairman_name ? true : false,
                },
              ]"
            />
            <label for="chairman_name">اسم رئيس مجلس الإدارة</label>
          </span>
          </div>
        </div>
        <div class="form-label-group">
          <div class="mt-4">
          <span class="p-float-label">
            <BaseInputNumber
              :label="'رقم جوال رئيس مجلس الإدارة'"
              v-model="payload.chairman_mobile" 
              :disabled="loading || payload.is_chairman_mobile_verified ||payload.the_charity_manager_is_the_chairman"
              :class="{ 'p-invalid': (submitted && !payload.chairman_mobile) ||
                        (payload.chairman_mobile && !verify_mobile(payload.chairman_mobile))
                      }"
              id="chairman_mobile"
              :maxLength="10"
              :phoneNumber="true"
            />
          </span>
            <div
              v-if="payload.chairman_mobile && !verify_mobile(payload.chairman_mobile)"
              class="invalid-email-label"
              style="color: #e24c4c"
            >
              الرجاء إدخال رقم جوال صحيح
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="border-container"></div>

    <div class="row">
      <div class="col-lg-6">
        <button
          id="close-btn"
          class="btn btn-lg btn-secondary btn-block close-btn"
          type="button"
          :disabled="loading"
          @click.stop="$emit('close')"
        >
          إغلاق
        </button>
      </div>
      <div class="col-lg-6">
        <button
          id="tracking-btn"
          class="btn btn-lg btn-primary btn-block tracking-btn"
          type="button"
          :disabled="loading"
          @click.stop="submit"
        >
          <span v-if="loading" class="spinner-border spinner-border-sm mx-1"></span>
          متابعة
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "primevue/checkbox";
import CharityRegisterService from "../../../services/charity-register.service";
import BaseCalendar from "@/components/layout/BaseCalendar";

export default {
  name: "create-account-step1",
  inject: ['staticUrl'],
  props: ["payload", "userExistFound"],
  emits: ["close", "next", "openVerificationStep1", "showConfirmDialog"],
  components: { Checkbox, BaseCalendar },
  data() {
    return {
      loading: false,
      error: null,
      submitted: false,
      initialData: null,
      currentdDate: new Date(),
    };
  },
  created() {
    this.payload.gregorian_birthdate = 
      this.payload.gregorian_birthdate 
      && new Date(this.payload.gregorian_birthdate) 
      || '';
  },
  methods: {
    submit() {
      this.submitted = true;
      if (
        this.payload.national_id &&
        this.verified_national_id &&
        this.payload.email &&
        this.verified_email &&
        this.payload.mobile_number &&
        this.verify_mobile(this.payload.mobile_number) &&
        this.payload.gregorian_birthdate &&
        this.payload.hijri_birthdate
      ) {
        if (!this.payload.the_charity_manager_is_the_chairman && (!this.payload.chairman_name || !this.verify_mobile(this.payload.chairman_mobile))) {
          return;
        }
        // check If data is changed or not
        this.initialData !== JSON.stringify(this.payload) ? this.sendToApi() : this.checkVerification(this.payload.id);
      }
    },
    verify_mobile(number) {
      const pattern =
        /^(009665|9665|\\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
      const result = pattern.test(number);

      return result;
    },
    sendToApi() {
      const payload = {
        national_id: this.payload.national_id,
        hijri_birthdate: this.payload.hijri_birthdate,
        gregorian_birthdate: this.payload.gregorian_birthdate,
        mobile_number: this.payload.mobile_number,
        email: this.payload.email,
        the_charity_manager_is_the_chairman:
          this.payload.the_charity_manager_is_the_chairman,
        chairman_name: this.payload.chairman_name,
        chairman_mobile: this.payload.chairman_mobile,
      };

      if (this.payload.the_charity_manager_is_the_chairman) {
        this.payload.chairman_name = null;
        this.payload.chairman_mobile = null;
        delete payload.chairman_name;
        delete payload.chairman_mobile;
      }

      this.loading = true;
      this.error = null;

      CharityRegisterService.step1(this.payload.id, payload)
        .then(({ data }) => {
          if(data.result.charity_request && !this.userExistFound) {
            this.$emit("showConfirmDialog", data.result.charity_request, data.result.id);
            return;
          }
          this.checkVerification(data.result.id);
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkVerification(id) {
      if (
        !this.payload.is_mobile_verified ||
        !this.payload.is_email_verified ||
        !this.$parent.verified_info.first_name
      ) {
        this.$emit("openVerificationStep1", id);
      } else {
        this.$emit("next");
      }
    }
  },
  computed: {
    verified_mobile() {
      const pattern =
        /^(009665|9665|\\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
      const result = pattern.test(this.payload.mobile_number);

      return result;
    },
    verified_email() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = pattern.test(this.payload.email);

      return result;
    },
    verified_national_id() {
      return (
        this.payload.national_id &&
        this.payload.national_id.length &&
        this.payload.national_id.length == 10
      );
    },
  },
  mounted() {
    this.initialData = JSON.stringify(this.payload);
  }
};
</script>

<style lang="scss" scoped>
.welcome-container {
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .sub-title {
    font-size: 18px;
  }
}
.information-container {
  color: #696cff;
  background-color: #e9e9ff;
  border-radius: 5px;
  height: 60px;
  font-size: 16px;
  border-right: 5px solid #696cff;
  display: flex;
  align-items: center;
}
.accept-checkbox {
  label {
    font-size: 14px;
    color: #3f3f3f;
    margin-right: 5px;
  }
}
.invalid-email-label {
  font-size: 14px;
  margin-top: 0.25rem;
}
.border-container {
  border-top: 1px solid #dadada;
  padding-top: 2rem;
  margin-top: 2rem;
}

.tracking-btn {
  display: block;
  margin: 0 auto;
  width: 80%;
  border-radius: 25px;
  padding: 10px;
  background: #12b2aa;
  color: #ffffff;
  border: none;
  font-size: 18px;
}

.tracking-btn:hover {
  background-color: #0e8e88 !important;
  border: none;
}

.close-btn {
  display: block;
  margin: 0 auto;
  width: 80%;
  border-radius: 25px;
  padding: 10px;
  background: #d9d9d9;
  color: #3f3f3f;
  border: none;
  font-size: 18px;
}
</style>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
